






















































































































import { TailwindTable } from '@/app/components';
import { useAxios, usePagination } from '@/app/composable';
import { ColumnPosition, ColumnType, UserRoles } from '@/app/constants';
import store from '@/app/store';
import { Asset } from '@/modules/asset/types';
import { UserStatus } from '@/modules/organization/constants/user.constants';
import { RetrievalQuery } from '@/modules/retrieval/interfaces';
import { Workflow } from '@/modules/workflow-designer/types';
import { BanIcon, CheckCircleIcon, MailOpenIcon, ScaleIcon, TrashIcon, XCircleIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref, Ref, watch } from '@vue/composition-api';
import { clone } from 'ramda';
import { UsersAPI } from '../../../organization/api';
import DerivedAssetsModal from '../../../organization/components/DerivedAssetsModal.vue';
import { AdminAPI } from '../../api';

export default defineComponent({
    name: 'Users',
    components: {
        TailwindTable,
        BanIcon,
        CheckCircleIcon,
        TrashIcon,
        DerivedAssetsModal,
        MailOpenIcon,
        XCircleIcon,
        ScaleIcon,
    },
    props: {
        query: {
            type: Object as PropType<{ sortBy: { field: string; asc: boolean } }>,
        },
    },
    setup(props, { root, emit }: { root: any; emit: any }) {
        const { exec, loading } = useAxios(true);
        const user = computed(() => store.state.auth.user);
        const showInviteUserModal = ref<boolean>(false);
        const derivedAssets = ref<Asset[]>([]);
        const derivedWorkflows = ref<Workflow[]>([]);
        const derivedRetrievalQueries = ref<RetrievalQuery[]>([]);
        const deletedUserId = ref<number | null>();
        const showConfirmDeleteModal = ref(false);

        const rolesMapping = {
            [UserRoles.Admin]: 'bg-red-100 text-red-800',
            [UserRoles.Manager]: 'bg-green-100 text-green-800',
            [UserRoles.ModelModerator]: 'bg-blue-100 text-blue-800',
            [UserRoles.Member]: 'bg-gray-100 text-gray-800',
            [UserRoles.Guest]: 'bg-gray-100 text-gray-800',
            [UserRoles.LegalRepresentative]: 'bg-purple-100 text-purple-800',
        };

        const columns = computed(() => [
            {
                key: 'id',
                label: 'ID',
                type: ColumnType.Integer,
            },
            {
                key: 'name',
                label: 'Name',
                type: ColumnType.String,
                sortable: true,
            },
            {
                key: 'organisation',
                label: 'Organisation',
                type: ColumnType.String,
                sortable: true,
            },
            {
                key: 'roles',
                label: 'Roles',
                type: ColumnType.Array,
                position: ColumnPosition.Center,
            },
            {
                key: 'status',
                label: 'Status',
                type: ColumnType.String,
                position: ColumnPosition.Center,
                sortable: true,
            },
            {
                key: 'actions',
                label: 'Actions',
                position: ColumnPosition.Right,
            },
        ]);

        const users: Ref<any> = ref<any>();
        const usersCount = ref<number>(0);
        const rows = computed(() => users?.value);
        const { pagination, handleChangePage } = usePagination(root, { total: computed(() => usersCount.value) });

        const fetchUsers = async () => {
            await exec(AdminAPI.getUsers(pagination.value, props.query?.sortBy))
                .then((res: any) => {
                    users.value = res.data.data;
                    usersCount.value = res.data.meta.totalItems;
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const sortedRoles = (value: string[]) => {
            const clonedValue = clone(value);
            const map = Object.fromEntries(Object.keys(rolesMapping).map((k: string, idx: number) => [k, idx]));

            clonedValue.sort((a, b) => map[a] - map[b]);
            return clonedValue;
        };

        const handleChangeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        const changePage = (newPage: number) => {
            handleChangePage(newPage);
            fetchUsers();
        };

        const activateUser = (id: number) => {
            exec(UsersAPI.activateMember(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Member has been activated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be activated due to an error.', 'Error');
                });
        };

        const suspendUser = (id: number) => {
            exec(UsersAPI.disableMember(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Member has been deactivated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be deactivated due to an error.', 'Error');
                });
        };

        const makeLegal = (id: number) => {
            exec(AdminAPI.makeLegal(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Legal representative role assigned', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to make legal representative.', 'Error');
                });
        };

        const removeLegal = (id: number) => {
            exec(AdminAPI.removeLegal(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Legal representative role unassigned', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to remove legal representative.', 'Error');
                });
        };

        const deactivateUserDry = (id: number) => {
            exec(UsersAPI.deactivateMemberDry(id))
                .then((response) => {
                    derivedAssets.value = response?.data.derivativeAssets;
                    derivedWorkflows.value = response?.data.derivativeWorkflows;
                    derivedRetrievalQueries.value = response?.data.derivativeRetrievalQueries;
                    deletedUserId.value = id;
                    showConfirmDeleteModal.value = true;
                })
                .catch(() => {
                    deletedUserId.value = null;
                    (root as any).$toastr.e('Member could not be deactivated due to an error.', 'Error');
                });
        };

        const deactivateUser = () => {
            if (!deletedUserId.value) return;
            exec(UsersAPI.deactivateMember(deletedUserId.value))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Member has been deactivated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be deactivated due to an error.', 'Error');
                })
                .finally(() => {
                    deletedUserId.value = null;
                    showConfirmDeleteModal.value = false;
                });
        };

        const resendInvitation = (id: number) => {
            exec(UsersAPI.resendInvitation(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Invitation has been resent', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to resend invitation.', 'Error');
                });
        };

        const cancelInvitation = (id: number) => {
            exec(UsersAPI.cancelInvitation(id))
                .then(() => {
                    fetchUsers();
                    (root as any).$toastr.s('Invitation has been cancelled', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to cancel invitation.', 'Error');
                });
        };

        const cancelDelete = () => {
            derivedAssets.value = [];
            derivedWorkflows.value = [];
            derivedRetrievalQueries.value = [];
            showConfirmDeleteModal.value = false;
        };

        watch(
            () => props.query,
            (query) => {
                if (query) {
                    fetchUsers();
                }
            },
            { immediate: true },
        );

        return {
            UserStatus,
            columns,
            rows,
            loading,
            pagination,
            user,
            rolesMapping,
            sortedRoles,
            handleChangeSort,
            changePage,
            activateUser,
            suspendUser,
            deactivateUser,
            deactivateUserDry,
            showConfirmDeleteModal,
            cancelDelete,
            derivedAssets,
            derivedWorkflows,
            derivedRetrievalQueries,
            resendInvitation,
            cancelInvitation,
            showInviteUserModal,
            makeLegal,
            removeLegal,
        };
    },
});
























































import { Toggle } from '@/app/components';
import { PlayIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import { InternalScheduleFrequency } from '../../constants';
import { InternalSchedule, InternalScheduleUpdate } from '../../types';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export default defineComponent({
    name: 'InternalSchedule',
    props: {
        schedule: { type: Object as PropType<InternalSchedule>, required: true },
        frequencies: { type: Array as PropType<{ label: string; key: string }[]>, required: true },
    },
    components: { Toggle, PlayIcon },
    setup(props, { emit }) {
        const defaultScheduleFrequency = (frequency: InternalScheduleFrequency): number => {
            switch (frequency) {
                case InternalScheduleFrequency.Hourly:
                    return 0;
                case InternalScheduleFrequency.Daily:
                    return 0;
                case InternalScheduleFrequency.Weekly:
                    return 1;
                case InternalScheduleFrequency.Monthly:
                    return 0;
                default:
                    return 0;
            }
        };
        const figureOutSchedule = (schedule: InternalSchedule, frequency: InternalScheduleFrequency): number => {
            const defaultSchedule = defaultScheduleFrequency(frequency);
            switch (frequency) {
                case InternalScheduleFrequency.Hourly:
                    return schedule.minute || defaultSchedule;
                case InternalScheduleFrequency.Daily:
                    return schedule.hour || defaultSchedule;
                case InternalScheduleFrequency.Weekly:
                    return schedule.dayOfWeek || defaultSchedule;
                case InternalScheduleFrequency.Monthly:
                    return schedule.dayOfMonth || defaultSchedule;
                default:
                    return defaultSchedule;
            }
        };

        const figureOutCron = (
            frequency: InternalScheduleFrequency,
            frequencySchedule: number,
        ): {
            dayOfWeek: number | null | undefined;
            dayOfMonth: number | null | undefined;
            month: number | null | undefined;
            hour: number | null | undefined;
            minute: number | null | undefined;
        } => {
            return {
                dayOfMonth: frequency === InternalScheduleFrequency.Monthly ? frequencySchedule : null,
                dayOfWeek: frequency === InternalScheduleFrequency.Weekly ? frequencySchedule : null,
                hour: frequency === InternalScheduleFrequency.Daily ? frequencySchedule : null,
                minute: frequency === InternalScheduleFrequency.Hourly ? frequencySchedule : null,
                month: null,
            };
        };

        const tempFrequency: Ref<InternalScheduleFrequency> = ref(
            props.schedule.frequency || InternalScheduleFrequency.Monthly,
        );

        const frequency: Ref<InternalScheduleFrequency> = computed({
            get: () => tempFrequency.value,
            set: (newFrequency: InternalScheduleFrequency) => {
                tempFrequency.value = newFrequency;
                frequencySchedule.value = defaultScheduleFrequency(newFrequency);
            },
        });

        const frequencySchedule: Ref<number> = ref(figureOutSchedule(props.schedule, frequency.value));

        const schedules: Ref<{ label: string; key: number }[]> = computed(() => {
            if (R.isNil(frequency.value)) return [];
            switch (frequency.value) {
                case InternalScheduleFrequency.Hourly:
                    return [...Array(60).keys()].map((minute: number) => {
                        return { label: `${minute} ${minute === 1 ? 'minute' : 'minutes'}`, key: minute };
                    });
                case InternalScheduleFrequency.Daily:
                    return [...Array(24).keys()].map((hour: number) => {
                        return { label: `${hour}:00 UTC`, key: hour };
                    });
                case InternalScheduleFrequency.Weekly:
                    return [
                        { label: 'Monday', key: 1 },
                        { label: 'Tuesday', key: 2 },
                        { label: 'Wednesday', key: 3 },
                        { label: 'Thursday', key: 4 },
                        { label: 'Friday', key: 5 },
                        { label: 'Saturday', key: 6 },
                        { label: 'Sunday', key: 0 },
                    ];
                case InternalScheduleFrequency.Monthly:
                    return [...Array(31).keys()].map((d: number) => {
                        const day = d + 1;
                        let suffix = 'th';
                        if (`${day}`.endsWith('1') && day !== 11) suffix = 'st';
                        else if (`${day}`.endsWith('2') && day !== 12) suffix = 'nd';
                        else if (`${day}`.endsWith('3') && day !== 13) suffix = 'rd';
                        return { label: `the ${day}${suffix}`, key: day };
                    });
                default:
                    return [];
            }
        });
        const whenPrefix: Ref<string> = computed(() => {
            switch (frequency.value) {
                case InternalScheduleFrequency.Weekly:
                case InternalScheduleFrequency.Monthly:
                    return 'on';
                default:
                    return 'at';
            }
        });

        const internalScheduleUpdate: Ref<InternalScheduleUpdate> = computed(
            (): InternalScheduleUpdate => {
                return {
                    frequency: frequency.value,
                    ...figureOutCron(frequency.value, frequencySchedule.value),
                };
            },
        );

        const next: Ref<{ label: string; tooltip: string }> = computed((): { label: string; tooltip: string } => {
            let n = dayjs.utc().second(0).millisecond(0);

            switch (frequency.value) {
                case InternalScheduleFrequency.Hourly:
                    if (frequencySchedule.value <= n.minute()) n = n.add(1, 'hour');
                    n = n.minute(frequencySchedule.value);
                    break;
                case InternalScheduleFrequency.Daily:
                    n = n.minute(0);
                    if (frequencySchedule.value <= n.hour()) n = n.add(1, 'day');
                    n = n.hour(frequencySchedule.value);
                    break;
                case InternalScheduleFrequency.Weekly:
                    n = n.hour(0).minute(0);
                    if (frequencySchedule.value <= n.day()) n = n.add(1, 'week');
                    n = n.day(frequencySchedule.value);
                    break;
                case InternalScheduleFrequency.Monthly:
                    n = n.hour(0).minute(0);
                    if (frequencySchedule.value <= n.date()) n = n.add(1, 'month');
                    n = n.date(frequencySchedule.value);
            }

            return { label: n.fromNow(true), tooltip: n.format('D MMM YYYY HH:mm:ss UTC') };
        });

        const toggle = () => {
            if (props.schedule.isEnabled) emit('disable', props.schedule.id);
            else emit('enable', props.schedule.id);
        };

        watch(
            () => internalScheduleUpdate.value,
            (newUpdate: InternalScheduleUpdate) => {
                emit('update', props.schedule.id, newUpdate);
            },
        );

        return {
            next,
            schedules,
            frequency,
            whenPrefix,
            frequencySchedule,
            toggle,
        };
    },
});
